// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/search-input.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/search-input.tsx");
  import.meta.hot.lastModified = "1713985513926.2927";
}
// REMIX HMR END

import { useState } from "react";
export default function SearchInput(props) {
  _s();
  const [query, setQuery] = useState("");
  const handleSubmit = event => {
    event.preventDefault();
    // blur the form
    event.currentTarget.querySelector("input")?.blur();
    props.onQuery(query);
  };
  return <form onSubmit={handleSubmit} className="w-full flex justify-start items-center gap-2">
      <input className="grow border border-gray-300 rounded-sm px-2 py-2 w-full" type="text" value={query} placeholder="e.g. greek myths" onChange={event => setQuery(event.target.value)} />
      <button className="grow-0 border border-blue-500 hover:bg-blue-100 text-blue-500 hover:text-blue-700 font-semibold py-2 px-4 rounded-sm" type="submit">
        Search
      </button>
    </form>;
}
_s(SearchInput, "HYX2QbDDdTtlu7GfoQbAPZOIM6k=");
_c = SearchInput;
var _c;
$RefreshReg$(_c, "SearchInput");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;